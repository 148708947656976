import React from 'react'

const About3 = ({
  title = 'kontakt',
  text = '',
}) => {
  return (
    <div style={{ padding: '0 0 0 0px'}}>
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
    
  )
}

export default About3;
