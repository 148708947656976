import React from 'react'

const About = ({
  title = 'über mich',
  text = '',
}) => {
  return (
    <div style={{ padding: '0 5px'}}>
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
    
  )
}

export default About;
