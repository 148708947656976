import React from 'react'
import cn from 'classnames'

import styles from './wrapper.module.scss'

const Wrapper = ({
    role="main",
    children,
    className,
}) => {
    return <main className={cn(styles.wrapper, className)} role={role}>{children}</main>
}

export default Wrapper