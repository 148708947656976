import React from 'react'

import styles from './glas.module.scss'


const Glas = ({
  title = 'Glasbläserei',
  text = '',
  // liste = '',
}) => {
  return (
    <div className={styles.introtext} >
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: text }}style={{ fontSize: '20px'}}></p>
      {/* <li dangerouslySetInnerHTML={{ __html: liste }}style={{ fontSize: '20px'}}></li> */}
      {/* <li style={{ fontSize: '20px'}}>- point 1</li>
      <li style={{ fontSize: '20px'}}>- point 1</li>

      <li style={{ fontSize: '20px'}}>- point 1</li>
      <li style={{ fontSize: '20px'}}>- point 1</li>
      <li style={{ fontSize: '20px'}}>- point 1</li>
      <li style={{ fontSize: '20px'}}>- point 1</li> */}

    </div>
    
  )
}

export default Glas;
