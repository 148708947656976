import React from 'react'
import { withPrefix } from 'gatsby'

import siteConfig from '../../../data/siteConfig'

import styles from './hero.module.scss'

const Hero = ({
    title,
    heroImg,
}) => {
    const backgroundImage = withPrefix(siteConfig.siteCover);

    return (
        <section
            className={styles.heroContainer}
            style={{ backgroundImage: `url(${heroImg || backgroundImage})` }}
        >
            {title && (
                <div className={styles.titleContainer}>
                    
                        <h1 className={styles.title}>{title}</h1>
                </div>
            )}
        </section>
    )
}

export default Hero
