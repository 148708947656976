
module.exports = {
  siteTitle: 'De Martino GmbH',
  siteDescription: `Die Webseite der De Martino GmbH in Zürich`,
  keyWords: ['De Martino', 'GmbH','De Martino GmbH', 'Glasbläser', 'Haustechnik', 'Sanitär', 'Zürich '],
  authorName: 'Ray',
  twitterUsername: 'De Martino',
  githubUsername: 'De Martino',
  authorAvatar: '/images/claudio_logo_transparent.png',
  authorDescription: `Ich biete 15 Jahre Erfahrung im Bereich Haustechnik, mit Spezialisierung auf Sanitär- und Heizungsanlagen und bin seit 2018  selbstständig in der Baubranche tätig. 
  <br /><br />
  Umbauten, Neuinstallationen, Reparaturen oder individuelle Problemlösungen - durch passgenaue Ergebnisse erreiche ich die höchste Kundenzufriedenheit. <br /><br />
  `,

  abouter: `Handwerkliches Arbeiten war schon immer meine grosse Leidenschaft. Planen und Analysieren sowie Probleme frühzeitig erkennen und Lösungen zu finden sind meine Spezialität. 

  <br><br/>
  Ich sehe mich als moderner Allrounder mit Spezialisierung im Bereich Haustechnik. 
  `,
  kontakt: `<strong>De Martino GmbH </strong> <br />
  Claudio De Martino<br />
  Glattstegweg 95 <br />  8051, Zürich<br />
  <br />
      <span class="contact-info">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="social-icon" size="32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M170.738 311.468c8.07-42.15 99.697-131.543 138.247-138.247 11.314 11.314 33.94 33.942 56.57 33.942l79.195-79.196c0-22.628-35.103-49.413-56.57-56.57C272.583 32.865 32.865 272.583 71.398 388.182c7.156 21.467 33.94 56.57 56.57 56.57l79.195-79.196c0-22.628-25.11-42.772-36.424-54.086z"></path></svg>
        <a href="tel:+41764540004">076 454 00 04</a><br />
      </span>
      <span class="contact-info">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="social-icon" size="32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"></path></svg>
        <a href="https://www.instagram.com/the_glass_jungle/" target=_blank>@the_glass_jungle</a><br />
      </span>
      <span class="contact-info">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="social-icon" size="32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 0 1 194 256h648.8a7.2 7.2 0 0 1 4.4 12.9z"></path></svg>
        <a href="mailto:claudio@de-martino.ch">claudio@de-martino.ch</a> <br />
      </span>
    `,

  intro: `Mit meinem noch jungen Unternehmen möchte ich Fuss in der Region Zürich fassen und durch Stammkundschaft sowie interne Zusammenarbeiten meine Zukunft in der Selbstständigkeit sichern. 
    `,

  haustechnik: `Durch meine langjährige Erfahrung im Bereich Haustechnik mit Schwerpunkt Sanitär und Heizungsanlagen, suche ich die Ideale Lösung um die Wünsche meiner Kunden & Kundinnen zufrieden zu stellen. `,
  haustech: `Spezialbereichen und Tätigkeiten`,
  glas: `Neben meiner Hauptarbeit als Haustechniker bin ich ein leidenschaftlicher Glasbläser mit eigenem Studio. Diese Arbeit bietet mir den perfekten Ausgleich zum Alltag und ich verfolge den Wunsch diesen Berufszweig weiter ausbauen zu können. 
    `,

  impressum: `Verantwortlich für den Inhalt  <br> </br> De Martino GmbH  <br> </br>  CHE-400.836.383  <br> </br> © De Martino GmbH - Bilder & Texte
  
  <br> </br>
  Bei Fragen zu unserer Arbeit o.a dürfen Sie uns gerne kontaktieren`,

  listeHaustechnik: [
    {
          name: 'HTML',
          level: 70
        },
        {
          name: 'CSS',
          level: 60
        },
        {
          name: 'Javascript',
          level: 50
        },
  ],
  // skills: [
  //   {
  //     name: 'HTML',
  //     level: 70
  //   },
  //   {
  //     name: 'CSS',
  //     level: 60
  //   },
  //   {
  //     name: 'Javascript',
  //     level: 50
  //   },
  //   {
  //     name: 'NodeJs',
  //     level: 40
  //   },
  //   {
  //     name: 'React',
  //     level: 60
  //   },
  //   {
  //     name: 'Git',
  //     level: 70
  //   }
  // ],
  jobs: [
    {
      company: "De Martino GmbH",
      begin: {
        month: 'Okt',
        year: '2019'
      },
      duration: null,
      occupation: "Haustechnik / Sanitär",
      description: "Spezialisiert auf den Bereich Heizkessel / Wassererwärmer- Sanierung, Umbauten von kleinen bis mittelgrosse Objekte, Rohrleitungsbau"
  
    }, 
    {
      company: "E.O.Knecht",
      begin: {
        month: 'apr',
        year: '2010'
      },
      duration: '9 Jahre',
      occupation: "Bauleitender Sanitär- / Heizungsmonteur",
      description: "Ausführen jeglicher Arbeiten im Bereich Haustechnik. Wasser Gas Kälte Wärme Heizung Solar Rohrleitungsbau Lüftung. Spezialisiert auf den Bereich Heizkessel/Wassererwärmer- Sanierung, Umbauten von kleinen bis mittelgrosse Objekte, Rohrleitungsbau"
  
    }, {
      company: "E.O.Knecht",
      begin: {
        month: 'Sep',
        year: '2008'
      },
      duration: '2 Jahre',
      occupation: "Heizungsinstallateur",
      description: "2 Jährige Zusatzausbildung im Bereich Heizung"
    }, {
      company: "E.O.Knecht",
      begin: {
        month: 'Sep',
        year: '2007'
      },
      duration: '3 Jahr',
      occupation: "Sanitärinstallateur",
      description: "3 Jährige Ausbildung als Sanitärinstallateur"
  
    },
    /* ... */
  ],
  social: {
    twitter: "https://twitter.com/de_martino",
    linkedin: "https://www.linkedin.com/in/de-martino",
    github: "https://github.com/de-martino",
    email: "claudio@de-martino.ch"
  },
  siteUrl: 'https://www.de-martino.ch/',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-000000000-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'De Martino GmbH',
      url: '/',
    }
  ]
}