import React from 'react'
import cn from 'classnames'

import siteConfig from '../../../data/siteConfig'

import styles from './timeline.module.scss'

const Timeline = ({ className }) => {
  return (
    <div className={cn(className, styles.timeline)}>
      <h2>Beruf</h2>
      {siteConfig.jobs && siteConfig.jobs.map(job => (
        <article key={job.begin.month + job.begin.year} className={styles.item}>
          <div className={styles.inner}>
            <span className={styles.date}>
              <span className={styles.month}>{job.begin.month}</span>
              <span className={styles.year}>{job.begin.year}</span>
            </span>
            <h2 className={styles.title}>{job.occupation} bei {job.company} <br /><small>({job.duration || 'present'})</small></h2>
            <p>{job.description}</p>
          </div>
        </article>
      ))}
    </div>
  )
}

export default Timeline
