import React, { Fragment, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Img from 'gatsby-image'

import styles from './grid-images.module.scss'

const GridImages = ({
  images,
  isLoading,
}) => {
 
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const toggleLightbox = (selectedIndex) => {
    setSelectedIndex(selectedIndex)
    setIsLightboxOpen(!isLightboxOpen)
  }

  return (
    <Fragment>
      {(!isLoading && images.length) ? (
        <div className={styles.gallery}>
          {images.map(({ author, caption, source, fluid }, j) => (
            <div
              className={styles.image}
              onClick={() => toggleLightbox(j)}
              key={source.thumbnail}
            >
              <Img
                sizes={fluid}
              />
            </div>
          ))}
        </div>
      ) : null}

      <ModalGateway>
        {isLightboxOpen && !isLoading ? (
          <Modal onClose={toggleLightbox}>
            <Carousel
              currentIndex={selectedIndex}
              style={{
                view: (base) => ({
                  ...base,
                  height: '80vh',
                }),
                container : () => ({
                  maxHeight: '80vh'
                })
              }}
              frameProps={{ autoSize: 'height' }}
              views={images}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
};

export default GridImages
