import React from 'react'
import { Tabs as AntdTabs } from 'antd'

import styles from './tabs.module.scss'

const { TabPane } = AntdTabs;

const Tabs = ({
    items
}) => {
return (
    <AntdTabs
        defaultActiveKey="0"
        centered
    
    >
        {items && items.map((item, idx) => (
            <TabPane
                tab={
                    <div className={styles.tabTitle}>
                        {item.icon}
                        <span>{item.title}</span>
                    </div>
                }
                key={idx}
            >
                {item.content}
            </TabPane>
        ))}
    </AntdTabs>
)};

export default Tabs