import React from 'react'

import styles from './sani.module.scss'


const Sani = ({
  title = 'Haustechnik',
  text = '',
  texter='',
  // liste = '',
}) => {
  return (
    <div className={styles.introtext} >
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: text }}style={{ fontSize: '20px'}}></p>
      <p dangerouslySetInnerHTML={{ __html: texter }}style={{ fontSize: '22px', fontWeight:'600'}}></p>

      {/* <li dangerouslySetInnerHTML={{ __html: liste }}style={{ fontSize: '20px'}}></li> */}
      <li style={{ fontSize: '20px'}}>- Heizkesselsanierungen (Gas, Öl, Erneuerbare Energien)</li>
      <li style={{ fontSize: '20px'}}>- Thermische Solaranlagen</li>

      <li style={{ fontSize: '20px'}}>- Jegliche anfallenden Heizungs- und Sanitärarbeiten </li>
      <li style={{ fontSize: '20px'}}>- Nasszellen Sanierung</li>
      <li style={{ fontSize: '20px'}}>- Küchensanierung</li>
      <li style={{ fontSize: '20px'}}>- Umbauten/Neubauten</li>
      <li style={{ fontSize: '20px'}}>- Rohrleitungsbau</li>
      <li style={{ fontSize: '20px'}}>- Schweissarbeiten (Autogen, TIG)</li>

    </div>
    
  )
}

export default Sani;
